@import 'material-icons/iconfont/material-icons.css';
@import 'colors';
@import 'variables';
@import 'medias';

::-webkit-scrollbar {
  width: 4px;
  height: 4px;

  &-track {
    background: #f1f1f1;
  }

  &-thumb {
    background: #888;
  }

  &-thumb:hover {
    background: #555;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

body {
  margin: 0 auto !important;
  max-width: 99.75rem;
  overflow-x: hidden;
  border-left: $border;
  border-right: $border;
  height: auto !important;

  &:has(.background-sidebar) {
    overflow: hidden;
  }
}

html {
  overflow: initial !important;
}

.primary {
  color: var(--ant-primary-color);
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-vh-100 {
  height: calc(100vh - $header-height);
}

.drag {
  cursor: grab;
}

.drag-col-xs {
  min-width: 80px !important;
  width: 80px;
}

.drag-col-md {
  min-width: 200px;
  width: 20%;
}

.drag-col-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-025 {
  gap: 0.25rem;
}

@include max-mobile {
  :has(.ant-picker-time) {
    .cdk-overlay-pane {
      top: 0 !important;
      left: 0 !important;

      .ant-picker-dropdown {
        position: fixed !important;
        top: 0.5rem !important;
        left: 0.5rem !important;
        right: 0.5rem !important;
        bottom: 0.5rem !important;
        z-index: 1000 !important;
        width: calc(100vw - 1rem);
        height: calc(100vh - 1rem);

        .ant-picker-panel {
          height: 100%;
          justify-content: space-between;
          overflow: auto;
        }

        .ant-picker-panel-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .ant-picker-date-panel,
          .ant-picker-month-panel,
          .ant-picker-year-panel,
          .ant-picker-decade-panel {
            width: 100%;

            .ant-picker-content {
              width: 100%;
            }
          }
        }
      }

      inner-popup {
        height: 100%;
      }

      .ant-picker-datetime-panel {
        flex-direction: column !important;
        height: 100%;

        .ant-picker-time-panel {
          height: 100%;

          .ant-picker-time-panel-column {
            overflow: auto !important;

            &::after {
              display: initial;
            }
          }
        }
      }
    }
  }
}

.auth-container {
  padding: 2rem 0;

  nz-card {
    margin: 0 auto;
    width: 450px;

    @include max-mobile {
      max-width: 90vw;
    }

    h2 {
      margin: 0;
      text-align: center;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .logo {
      height: 1.5rem;
      margin-bottom: 1rem;
    }

    .link {
      text-align: center;
    }
  }
}

ngx-pull-to-refresh {
  width: 100%;

  .ngx-ptr-loadingbar-container {
    z-index: 1 !important;
  }
}

input[type='date'] {
  display: block;
  height: 32px !important;
  width: 100% !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none;

  &::-webkit-date-and-time-value {
    text-align: left !important;
  }
}


.pac-container {
  border-radius: 0.25rem;
  border: 0.5px solid rgba(51, 51, 51, 0.1);
  box-shadow: none;
  color: #00000073;
}

.pac-item {
  padding: 0.25rem 0.5rem;
}

.pac-icon {
  display: none;
}
